exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-detail-page-js": () => import("./../../../src/templates/blog-detail-page.js" /* webpackChunkName: "component---src-templates-blog-detail-page-js" */),
  "component---src-templates-blog-listing-page-js": () => import("./../../../src/templates/blog-listing-page.js" /* webpackChunkName: "component---src-templates-blog-listing-page-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-product-customiser-page-js": () => import("./../../../src/templates/product-customiser-page.js" /* webpackChunkName: "component---src-templates-product-customiser-page-js" */),
  "component---src-templates-product-detail-page-js": () => import("./../../../src/templates/product-detail-page.js" /* webpackChunkName: "component---src-templates-product-detail-page-js" */),
  "component---src-templates-product-listing-page-js": () => import("./../../../src/templates/product-listing-page.js" /* webpackChunkName: "component---src-templates-product-listing-page-js" */)
}

